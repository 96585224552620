import { lowerCamelCase } from '../utils/helpers';
import Accordion from '../components/Accordion/Accordion.static';
import Autocomplete from '../components/Forms/Autocomplete/Autocomplete.static';
import DatePicker from '../components/Forms/DatePicker/DatePicker.static';
import BlockAction from '../components/BlockAction/BlockAction.static';
import Carousel from '../components/Carousel/Carousel.static';
import CarouselPromotions from '../components/CarouselPromotions/CarouselPromotions.static';
import Dropdown from '../components/Dropdown/Dropdown.static';
import File from '../components/Forms/File/File.static';
import Loader from '../components/Loader/Loader.static';
import Modal from '../components/Modal/Modal.static';
import RangeSlider from '../components/Forms/RangeSlider/RangeSlider.static';
import Table from '../components/Table/Table.static';
import Tabs from '../components/Tabs/Tabs.static';
import Tooltip from '../components/Tooltip/Tooltip.static';
import InputStepper from '../components/Forms/InputStepper/InputStepper.static';

import moduleFactory, {
  initModulesWithin,
  destroyModulesWithin,
} from './lib/moduleFactory';
import Toggle from './modules/Toggle';
import SameHeight from './modules/SameHeight';

export const moduleSelectorPairs = [
  {
    Module: Accordion,
    name: 'Accordion',
    selector: '[data-accordion]',
  },
  {
    Module: Autocomplete,
    name: 'Autocomplete',
    selector: '[data-autocomplete]',
  },
  {
    Module: DatePicker,
    name: 'DatePicker',
    selector: '[data-datepicker]',
  },
  {
    Module: Dropdown,
    name: 'Dropdown',
    selector: '[data-dropdown]',
  },
  {
    Module: BlockAction,
    name: 'BlockAction',
    selector: '[data-block-action]',
  },
  {
    Module: Carousel,
    name: 'Carousel',
    selector: '[data-carousel]',
  },
  {
    Module: CarouselPromotions,
    name: 'CarouselPromotions',
    selector: '[data-carousel-promotions]',
  },
  {
    Module: File,
    name: 'File',
    selector: '[data-file-input]',
  },
  {
    Module: Loader,
    name: 'Loader',
    selector: '[data-loader]',
  },
  {
    Module: Modal,
    name: 'Modal',
    selector: '[data-modal]',
  },
  {
    Module: SameHeight,
    name: 'SameHeight',
    selector: '[data-same-height]',
  },
  {
    Module: RangeSlider,
    name: 'RangeSlider',
    selector: '[data-rangeslider]',
  },
  {
    Module: InputStepper,
    name: 'InputStepper',
    selector: '[data-inputstepper]',
  },
  {
    Module: Table,
    name: 'Table',
    selector: '[data-table]',
  },
  {
    Module: Tabs,
    name: 'Tabs',
    selector: '[data-tabs]',
  },
  {
    Module: Toggle,
    name: 'Toggle',
    selector: '[data-toggle]',
  },
  {
    Module: Tooltip,
    name: 'Tooltip',
    selector: '[data-tooltip]',
  },
];

window.ODS = window.ODS || {
  modules: {},
  instances: {},
  moduleSelectorPairs: {},
};
window.ODS.initModulesWithin = initModulesWithin;
window.ODS.destroyModulesWithin = destroyModulesWithin;
window.ODS.moduleSelectorPairs = moduleSelectorPairs;

window.addEventListener('DOMContentLoaded', () => {
  moduleSelectorPairs.forEach(module => {
    window.ODS.modules[module.name] = module.Module;
    window.ODS.instances[lowerCamelCase(module.name)] = moduleFactory(
      module.Module,
      module.selector
    );
  });

  window.dispatchEvent(new Event('ODSLoaded'));
});
