export default {
  prevMonth: 'previous month',
  nextMonth: 'next month',
  month: 'Month',
  year: 'Year',
  weekdays: [
    { name: 'Sunday', shortname: 'Su' },
    { name: 'Monday', shortname: 'Mo' },
    { name: 'Tuesday', shortname: 'Tu' },
    { name: 'Wednesday', shortname: 'We' },
    { name: 'Thursday', shortname: 'Th' },
    { name: 'Friday', shortname: 'Fr' },
    { name: 'Saturday', shortname: 'Sa' },
  ],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  openButton: 'Choose date',
  closeButton: 'Close',
};
