// ---------------------------------------------------
// Toggle
// module for handling toggle actions
// eslint-disable-next-line import/no-cycle
import toggle from './toggleUtil';

export const TRIGGER_EVT = 'toggleTrigger';
export const TOGGLE_EVT = 'toggle';

const defaultConfig = {
  activeClass: 'is-active',
  settingsAttr: 'data-toggle',
};

class Toggle {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.settings = {};
    this.targets = [];

    this.handleClick = this.handleClick.bind(this);

    this.element.ODS_Toggle = this;

    this.init();

    return this;
  }

  doToggle(props) {
    const { dispatchEvent = true } = props;
    let { element, attribute, value, action } = props;

    const returnProps = { ...props };
    const eventDetail = { trigger: this.element, element };

    if (attribute === 'icon') {
      attribute = 'xlink:href';
      element = element.querySelector('use');
    }

    if (attribute === 'text') {
      element = element.querySelector('[data-toggle-text-target]') || element;
    }

    if (typeof value === 'undefined') {
      value = false;
    }

    returnProps.value = toggle({
      element,
      attribute,
      action,
      value,
      dispatchEvent,
      eventDetail,
    });

    return returnProps;
  }

  init() {
    this.settings = this.parseSettings();

    this.targets = this.setupTargets();

    this.element.addEventListener('click', this.handleClick);

    return this.targets;
  }

  handleClick() {
    this.targets = this.targets.map(target => this.doToggle(target));

    this.element.dispatchEvent(new CustomEvent(TRIGGER_EVT, { bubbles: true }));
  }

  destroy() {
    this.element.removeEventListener('click', this.handleClick);
    this.settings = [];
    this.targets = [];
  }

  update() {
    this.removeNonExistentTargets();
    this.addNewTargets();
  }

  static getTargetElementsFromString({ target, trigger }) {
    const selector = target.trim();

    if (selector === 'self') {
      return [trigger];
    }

    if (selector === 'parent') {
      return [trigger.parentNode];
    }

    if (selector === 'previous') {
      return [trigger.previousElementSibling];
    }

    if (selector === 'next') {
      return [trigger.nextElementSibling];
    }

    if (typeof selector === 'string') {
      // don't be tempted to remove this querySelectorAll
      // selector like '.target-elements' whould not work as expected
      return Array.from(document.querySelectorAll(selector));
    }

    // add log why we endup here
    return selector;
  }

  parseSettings() {
    const jsonSettings = this.element.getAttribute(this.config.settingsAttr);
    const ariaControls = this.element.getAttribute('aria-controls');

    let settings = JSON.parse(jsonSettings);

    if (ariaControls) {
      const ariaExpanded = this.element.getAttribute('aria-expanded');

      settings = [
        ...(ariaExpanded
          ? [
              {
                target: 'self',
                attribute: 'aria-expanded',
              },
            ]
          : []),
        {
          target: ariaControls
            .split(' ')
            .map(a => `#${a}`)
            .join(','),
          attribute: 'hidden',
        },
        ...(Array.isArray(settings) ? settings : []),
      ];
    }

    return settings;
  }

  // get all the possible settings for all target elements
  setupTargets() {
    const trigger = this.element;

    return this.settings.reduce(
      (allSettings, setting) => [
        ...allSettings,
        ...setting?.target
          .split(',') // separate target selectors
          .reduce(
            (targetSettings, target) => [
              ...targetSettings,
              ...Toggle
                // get target elements from DOM
                .getTargetElementsFromString({
                  target,
                  trigger,
                })
                // duplicate setting for each DOM element
                .map(element => ({
                  ...setting,
                  element,
                })),
            ],
            []
          ),
      ],
      []
    );
  }

  removeNonExistentTargets() {
    this.targets = this.targets.filter(target =>
      document.contains(target.element)
    );
  }

  addNewTargets() {
    const allTargets = this.setupTargets();
    const newTargets = allTargets.filter(
      newTarget =>
        !this.targets.find(
          target =>
            target.element === newTarget.element &&
            target.action === newTarget.action
        )
    );

    this.targets = [...this.targets, ...newTargets];
  }

  static getInstance(el) {
    return el && el.ODS_Toggle ? el.ODS_Toggle : null;
  }
}

export default Toggle;

export const { getTargetElements } = Toggle;
export const { setTargets } = Toggle;
