export const CLASS_ROOT = 'carousel-promotions';

export const CLASS_WRAPPER = `${CLASS_ROOT}`;
export const CLASS_VIEWPORT_WRAPPER = `${CLASS_ROOT}__viewport-wrapper`;
export const CLASS_VIEWPORT = `${CLASS_ROOT}__viewport`;
export const CLASS_TRACK = `${CLASS_ROOT}__track`;
export const CLASS_SLIDE = `${CLASS_ROOT}__slide`;
export const CLASS_SLIDE_CONTENT = `${CLASS_ROOT}__content`;
export const CLASS_TITLE = `${CLASS_ROOT}__title`;
export const CLASS_SUBTITLE = `${CLASS_ROOT}__subtitle`;
export const CLASS_IMAGE = `${CLASS_ROOT}__image`;
export const CLASS_NEXT = `${CLASS_ROOT}__next`;
export const CLASS_DOTS = `${CLASS_ROOT}__pagination`;
export const CLASS_DOT_SVG = `${CLASS_ROOT}__pagination-svg`;
export const CLASS_DOT_CIRCLE = `${CLASS_ROOT}__pagination-circle`;
export const CLASS_SHOWING_INVERTED_SLIDE = `${CLASS_ROOT}--slide-inverted`;

export const CLASS_AUTOPLAY = `is-autoplay`;

export const SELECTOR_WRAPPER = `.${CLASS_WRAPPER}`;
export const SELECTOR_VIEWPORT = `.${CLASS_VIEWPORT}`;
export const SELECTOR_NEXT = `.${CLASS_NEXT}`;
export const SELECTOR_DOTS = `.${CLASS_DOTS}`;
