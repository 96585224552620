import noUiSlider from 'nouislider';
import wnumb from 'wnumb';

import defaultConfig from './data';

export default class RangeSlider {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.inputs = [];
    this.outputs = [];
    this.handleSliderUpdate = this.handleSliderUpdate.bind(this);
    this.element.ODS_RangeSlider = this;

    this.init();

    return this;
  }

  init() {
    this.config = {
      ...this.config,
      ...JSON.parse(this.element.getAttribute('data-rangeslider-config')),
    };

    this.config.format = wnumb(this.config.format);

    if (this.element.noUiSlider) {
      this.element.noUiSlider = undefined;
      this.instance?.destroy && this.instance.destroy();
    }

    this.instance = noUiSlider.create(this.element, this.config);
    this.inputs = Array.from(
      document.querySelectorAll(
        `input[name^=${this.element.getAttribute('id')}-]`
      )
    );

    this.outputs = this.inputs.map(input => {
      const outputElement = document.getElementById(
        `${input.getAttribute('id')}-output`
      );
      if (outputElement) {
        return {
          element: outputElement,
          format:
            outputElement.hasAttribute('data-format') &&
            wnumb(JSON.parse(outputElement.getAttribute('data-format'))),
        };
      }
      return false;
    });

    this.instance.on('update', this.handleSliderUpdate);
  }

  update() {
    this.instance.destroy();
    this.instance.init();
  }

  destroy() {
    this.instance.destroy();
    this.element.ODS_RangeSlider = null;
  }

  static getInstance(el) {
    return el && el.ODS_RangeSlider ? el.ODS_RangeSlider : null;
  }

  handleSliderUpdate(values, handle) {
    const value = values[handle];

    this.inputs[handle].value = value;

    if (this.outputs[handle]) {
      const output = this.outputs[handle];
      if (output.format) {
        output.element.innerText = output.format.to(
          this.config.format.from(value) // noUiSlider returns the value as a string, so we need to format it back to a Number
        );
      } else {
        output.element.innerText = value;
      }
    }
  }
}
