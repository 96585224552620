export default class InputStepper {
  constructor(element) {
    this.element = element;
    this.input = null;
    this.add = null;
    this.remove = null;
    this.min = 1;
    this.max = 999;
    this.defaultValue = 1;
    this.element.ODS_InputStepper = this;
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleButtonState = this.handleButtonState.bind(this);

    this.init();
  }

  handleInputClick() {
    this.input && this.input.select();
  }

  handleButtonState() {
    if (
      this.add &&
      typeof this.max === 'number' &&
      this.input.value >= this.max
    ) {
      this.add.setAttribute('aria-disabled', true);
      this.add.setAttribute('disabled', true);
    } else {
      this.add.removeAttribute('aria-disabled');
      this.add.removeAttribute('disabled');
    }
    if (
      this.remove &&
      typeof this.min === 'number' &&
      this.input.value <= this.min
    ) {
      this.remove.setAttribute('aria-disabled', true);
      this.remove.setAttribute('disabled', true);
    } else {
      this.remove.removeAttribute('aria-disabled');
      this.remove.removeAttribute('disabled');
    }
  }

  handleInputChange(e) {
    if (e) {
      if (this.min && e.target.value <= this.min) {
        e.target.value = this.min;
      }
      if (this.max && e.target.value >= this.max) {
        e.target.value = this.max;
      }
    }
  }

  handleButtonClick(e) {
    if (e.target.classList.contains('input-stepper__button--minus')) {
      if (this.min && this.input.value <= this.min) {
        if (e.target.getAttribute('disabled') === 'true') return;
        e.target.setAttribute('aria-disabled', true);
        e.target.setAttribute('disabled', true);
        return;
      }
      this.input.value = parseInt(this.input.value, 10) - 1;
    }
    if (e.target.classList.contains('input-stepper__button--plus')) {
      if (this.max && this.input.value >= this.max) {
        if (e.target.getAttribute('disabled') === 'true') return;
        e.target.setAttribute('aria-disabled', true);
        e.target.setAttribute('disabled', true);
        return;
      }
      this.input.value = parseInt(this.input.value, 10) + 1;
    }
    this.handleButtonState();
  }

  init() {
    this.input = this.element.querySelector('.input-stepper__field');
    this.add = this.element.querySelector('.input-stepper__button--plus');
    this.remove = this.element.querySelector('.input-stepper__button--minus');
    this.input.value = this.defaultValue;
    const jsonSettings = this.element.getAttribute('data-inputstepper');

    let { min, max, defaultValue } = JSON.parse(jsonSettings);
    if (defaultValue) {
      this.input.value = defaultValue;
    }
    if (min !== undefined) {
      this.min = parseInt(min, 10);
    }
    if (max !== undefined) {
      this.max = parseInt(max, 10);
    }

    if (!this.element.classList.contains('input-stepper--disabled')) {
      this.add && this.add.addEventListener('click', this.handleButtonClick);
      this.remove &&
        this.remove.addEventListener('click', this.handleButtonClick);
      this.input && this.input.addEventListener('click', this.handleInputClick);
      this.input &&
        this.input.addEventListener('change', this.handleInputChange);
      this.handleButtonState();
    }
  }

  update() {
    this.destroy();
    this.init();
  }

  destroy() {
    this.input &&
      this.input.removeEventListener('click', this.handleInputClick);
    this.input &&
      this.input.removeEventListener('change', this.handleInputChange);
    this.add && this.add.removeEventListener('click', this.handleButtonClick);
    this.remove &&
      this.remove.removeEventListener('click', this.handleButtonClick);
  }

  static getInstance(el) {
    return el && el.ODS_InputStepper ? el.ODS_InputStepper : null;
  }
}
