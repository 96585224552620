import colors from './../styles/export/color.scss';
import { isObject } from './helpers';

/**
 * Format color to correct object format by color name
 * if more shades are defined
 * {
 *   [shade1]: [hex1],
 *   [shade2]: [hex2],
 *   ...
 * }
 * @param {string} colorName
 * @param {array} colorPalette
 */
export function getShadesByName(colorName, colorPalette = colors) {
  if (typeof colorPalette[colorName] !== 'string') {
    // template for color with shade
    const regex = new RegExp(colorName + '-(\\d+)$');
    // filter color shades
    const names = Object.keys(colorPalette).filter(color => color.match(regex));

    // color has more shades defined
    if (names.length) {
      return names.reduce((acc, name) => {
        const shade = name.replace(colorName + '-', '');
        return {
          ...acc,
          [shade]: colorPalette[name],
        };
      }, {});
      // color does not exist
    } else {
      throw new Error(`color ${colorName} does not exist in colorPalette`);
    }
  }

  return colorPalette[colorName];
}

// eslint-disable-next-line no-shadow
export function getPaletteFromColors(color) {
  if (isObject(color)) {
    return Object.keys(color).reduce(
      (acc, shade) => {
        acc.value[shade] = {
          value: { hex: color[shade] },
        };
        return acc;
      },
      { type: 'SassMap', value: {} }
    );
  }
  return { value: { hex: color } };
}
