import A11yDialog from 'a11y-dialog';
import { isTabbable } from 'tabbable';

const defaultConfig = () => ({
  /** Class which indicates that modal is open */
  classModalIsOpen: 'is-active',
  /** Class on body which indicates that modal is open */
  classModalIsOpenBody: 'has-modal',
  /** Root of page content which should be hidden when modal is open */
  root: '#root',
  /** Move modal into this element selector (must be unique in DOM) */
  modalsRoot: '#root-modals',
});

export default class Modal {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig(), ...config };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    Modal.moveToModalRoot = Modal.moveToModalRoot.bind(this);
    Modal.lockBody = Modal.lockBody.bind(this);
    Modal.unlockBody = Modal.unlockBody.bind(this);

    this.element.ODS_Modal = this;

    this.init();

    return this;
  }

  static getInstance(el) {
    return el && el.ODS_Modal ? el.ODS_Modal : null;
  }

  handleShow(el) {
    this.constructor.lockBody();
    if (el) el.classList.add(this.config.classModalIsOpen);

    // Long modals can have first focusabble element under the fold, so static content above the fold should be focused instead to prevent undesirable scrolling
    const initialFocusEl = el.querySelector('[data-a11y-dialog-initial-focus]');

    if (!initialFocusEl) {
      return;
    }

    // set tabindex to -1 so element can't be focused via keyboard
    if (!isTabbable(initialFocusEl)) {
      initialFocusEl.setAttribute('tabindex', '-1');
    }

    initialFocusEl.focus();
  }

  handleHide(el) {
    this.constructor.unlockBody();
    if (el) el.classList.remove(this.config.classModalIsOpen);
  }

  show() {
    this.instance.show();
  }

  hide() {
    this.instance.hide();
  }

  init() {
    if (this.config.modalsRoot) {
      Modal.moveToModalRoot(
        this.element,
        document.querySelector(this.config.modalsRoot)
      );
    }

    this.instance = new A11yDialog(
      this.element,
      document.querySelector(this.config.root)
    );

    this.instance.on('show', this.handleShow);

    this.instance.on('hide', this.handleHide);
  }

  destroy() {
    this.instance.destroy();
    this.element.ODS_Modal = this;
  }

  update() {
    this.destroy();
    this.init();
  }

  static moveToModalRoot(el, container) {
    if (container) {
      container.appendChild(el);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `\`modalsRoot: ${this.config.modalsRoot}\` element is not present in DOM. Modal will be placed inside content which can affect it's styling. Please provide \`modalsRoot\` selector (should be placed outside of main contant, usualy in end of <body /> tag)`
      );
    }
  }

  static lockBody(
    className = this.config.classModalIsOpenBody,
    root = this.config.root
  ) {
    const container = document.querySelector(root);
    // store current scrollTop value
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    document.body.setAttribute('data-lock-scrolltop', scrollTop);

    // add locking styles to body
    document.body.style.height = '100%';
    document.body.style.width = '100%';
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';

    // add locking styles to scrollTop
    if (container) {
      /* eslint-disable no-param-reassign */
      container.style.height = '100%';
      container.style.width = '100%';
      container.style.overflow = 'hidden';
      container.style.position = 'fixed';
      // scroll page-container to scrollTop position
      container.scrollTop = scrollTop;
      /* eslint-enable no-param-reassign */
    }

    // add modal class
    document.body.classList.add(className);

    // attempt to scroll top fixed position
    window.requestAnimationFrame(() => {
      window.scrollTo(0, scrollTop);
    });
  }

  static unlockBody(
    className = this.config.classModalIsOpenBody,
    root = this.config.root
  ) {
    const container = document.querySelector(root);
    const scrollTop = document.body.getAttribute('data-lock-scrolltop');

    // remove locking styles from body
    document.body.style.height = '';
    document.body.style.width = '';
    document.body.style.overflow = '';
    document.body.style.position = '';

    // add modal class
    document.body.classList.remove(className);

    // remove locking styles from page-container
    if (container) {
      /* eslint-disable no-param-reassign */
      container.style.height = '';
      container.style.width = '';
      container.style.overflow = '';
      container.style.position = '';
      /* eslint-enable no-param-reassign */
    }

    // set scroll position back
    window.requestAnimationFrame(() => {
      window.scrollTo({ left: 0, top: scrollTop, behavior: 'instant' });
    });
  }
}
