import ResizeObserver from 'resize-observer-polyfill';

import toggle from '../../scripts/modules/toggleUtil';

export const defaultConfig = {
  expandButtonSelector: '[data-subrow-toggle]',
  scrollableContainerSelector: '.table-scrollable',
  responsiveTableSelector: `[data-table="responsive"]`,
};

export default class Table {
  constructor(element, config) {
    this.element = element;

    this.config = { ...defaultConfig, ...config };

    this.addRole = this.addRole.bind(this);
    this.addARIA = this.addARIA.bind(this);
    this.onExpandClick = this.onExpandClick.bind(this);

    this.expandButtons = [];
    this.scrollableContainer = null;

    this.element.ODS_Table = this;

    this.init();

    return this;
  }

  addRole(selector, role) {
    const items = Array.from(this.element.querySelectorAll(selector));

    items.forEach(item => {
      item.setAttribute('role', role);
    });
  }

  addARIA() {
    try {
      this.element.setAttribute('role', 'table');
      this.addRole('thead, tbody, tfoot', 'rowgroup');
      this.addRole('tr', 'row');
      this.addRole('td', 'cell');
      this.addRole('th', 'columnheader');
      this.addRole('th[scope=row]', 'rowheader');
      // caption role not needed as it is not a real role and
      // browsers do not dump their own role with display block
    } catch (e) {
      console.log('Table.addARIA(): ' + e);
    }
  }

  onExpandClick(e) {
    const button = e.currentTarget;
    const ariaControls = button.getAttribute('aria-controls') ?? '';
    const controlRows = this.element.querySelectorAll(
      ariaControls
        .split(' ')
        .map(id => `#${id}`)
        .join(',')
    );

    toggle({
      element: button,
      attribute: 'aria-expanded',
    });

    controlRows.forEach(row => {
      toggle({
        element: row,
        attribute: 'hidden',
      });
    });
  }

  resizeObserver = new ResizeObserver(entries => {
    const containerRect = this.scrollableContainer.getBoundingClientRect();

    entries.forEach(entry => {
      if (entry.contentRect.width > containerRect.width) {
        this.scrollableContainer.setAttribute('tabindex', '0');
      } else {
        this.scrollableContainer.removeAttribute('tabindex');
      }
    });
  });

  init() {
    this.expandButtons = Array.from(
      this.element.querySelectorAll(this.config.expandButtonSelector)
    );

    this.expandButtons.forEach(button => {
      button.addEventListener('click', this.onExpandClick);
    });

    if (this.element.matches(this.config.responsiveTableSelector)) {
      this.addARIA();
    }
    this.maybeInitScrollable();
  }

  maybeInitScrollable() {
    this.scrollableContainer = this.element.closest(
      this.config.scrollableContainerSelector
    );
    return (
      this.scrollableContainer && this.resizeObserver.observe(this.element)
    );
  }

  destroy() {
    this.expandButtons.forEach(button => {
      button.removeEventListener('click', this.onExpandClick);
    });

    this.expandButtons = [];
  }

  update() {
    this.destroy();
    this.init();
  }

  static getInstance(el) {
    return el && el.ODS_Table ? el.ODS_Table : null;
  }
}
