export default {
  start: [0],
  connect: false,
  range: {
    min: 0,
    max: 100,
  },
  cssPrefix: 'range-slider',
  cssClasses: {
    target: '__target',
    base: '__base',
    origin: '__origin',
    handle: '__handle',
    handleLower: '__handle--lower',
    handleUpper: '__handle--upper',
    touchArea: '__touch-area',
    horizontal: '--horizontal',
    vertical: '--vertical',
    background: '__background',
    connect: '__connect',
    connects: '__connects',
    ltr: '--ltr',
    rtl: '--rtl',
    draggable: '--draggable',
    drag: '--state-drag',
    tap: '--state-tap',
    active: '--active',
    tooltip: '__tooltip',
    pips: '__pips',
    pipsHorizontal: '__pips--horizontal',
    pipsVertical: '__pips--vertical',
    marker: '__marker',
    markerHorizontal: '__marker--horizontal',
    markerVertical: '__marker--vertical',
    markerNormal: '__marker--normal',
    markerLarge: '__marker--large',
    markerSub: '__marker--sub',
    value: '__value',
    valueHorizontal: '__value--horizontal',
    valueVertical: '__value--vertical',
    valueNormal: '__value--normal',
    valueLarge: '__value--large',
    valueSub: '__value--sub',
  },
  format: {
    decimals: 0,
  },
};
