import { lowerCamelCase } from '../../utils/helpers';

export default function moduleFactory(Module, selector, options = {}) {
  const elements = Array.from(document.querySelectorAll(selector));

  return elements.map(element => new Module(element, options));
}

export function destroyModulesWithin(container, removeOrphanedNodes = false) {
  const destroyedInstances = {};

  Object.keys(window.ODS.instances).forEach(moduleName => {
    window.ODS.instances[moduleName].forEach(instance => {
      if (typeof destroyedInstances[moduleName] !== 'object') {
        destroyedInstances[moduleName] = [];
      }

      let isInContainer = container.contains(instance.element);
      if (moduleName === 'modal') {
        // eslint-disable-next-line
        isInContainer = instance.instance?._openers.every(opener =>
          container.contains(opener)
        );
      } else if (moduleName === 'tooltip') {
        isInContainer = container.contains(instance.trigger);
      }

      if (isInContainer) {
        instance.destroy();
        destroyedInstances[moduleName].push(instance);
      }

      if (
        isInContainer &&
        removeOrphanedNodes &&
        ['modal', 'tooltip'].includes(moduleName)
      ) {
        instance.element.remove();
      }
    });

    window.ODS.instances[moduleName] = window.ODS.instances[moduleName].filter(
      instance => !destroyedInstances[moduleName].includes(instance)
    );
  });

  return destroyedInstances;
}

export function initModulesWithin(container) {
  const instances = {};
  window.ODS.moduleSelectorPairs.forEach(module => {
    const name = lowerCamelCase(module.name);
    const elements = Array.from(container.querySelectorAll(module.selector));
    if (window && window.ODS && window.ODS.instances[name]) {
      window.ODS.instances[name].forEach(instance => {
        elements.forEach((element, index) => {
          if (element.isSameNode(instance.element)) {
            elements.splice(index, 1);
          }
        });
      });
    }
    if (elements.length) {
      instances[name] = elements.map(element => new module.Module(element));
      window.ODS.instances[name] = [
        ...window.ODS.instances[name],
        ...instances[name],
      ];
    }
  });
  return instances;
}
