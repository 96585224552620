// eslint-disable-next-line import/no-cycle
import { TOGGLE_EVT } from './Toggle';

export default function toggle(props) {
  const {
    element,
    attribute,
    action,
    value,
    dispatchEvent = true,
    eventDetail,
  } = props;
  let returnValue = null;

  if (!element || !(element instanceof Element)) {
    return returnValue;
  }

  if (action === 'focus') {
    setTimeout(element.focus(), 20);
  }

  if (action === 'click') {
    element.click();
  }

  if (attribute === 'class') {
    element.classList.toggle(value);
    returnValue = value;
  }

  if (attribute === 'text') {
    const oldText = element.textContent;
    element.textContent = value;
    returnValue = oldText;
  }

  if (
    attribute !== 'class' &&
    attribute !== 'text' &&
    typeof action === 'undefined'
  ) {
    returnValue = element.getAttribute(attribute);

    if (attribute === 'hidden') {
      if (element.hasAttribute(attribute)) {
        element.removeAttribute(attribute);
      } else {
        element.setAttribute(attribute, '');
      }
    } else if (!value) {
      element.setAttribute(attribute, !(returnValue === 'true'));
    } else {
      element.setAttribute(attribute, value);
    }
  }

  if (dispatchEvent) {
    element.dispatchEvent(
      new CustomEvent(TOGGLE_EVT, {
        detail: eventDetail,
        bubbles: true,
      })
    );
  }

  return returnValue;
}
