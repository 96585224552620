import Glider from '@lighting-beetle/glider-js';

import {
  SELECTOR_PREV,
  SELECTOR_NEXT,
  SELECTOR_DOTS,
  SELECTOR_VIEWPORT,
} from './constants';

export const defaultConfig = {
  arrows: {
    prev: SELECTOR_PREV,
    next: SELECTOR_NEXT,
  },
  slidesToShow: 1.2,
  dots: SELECTOR_DOTS,
  skipTrack: true,
  classes: {
    glider: 'carousel__viewport',
    track: 'carousel__track',
    slide: 'carousel__slide',
    drag: 'is-dragging',
    draggable: 'is-draggable',
    disabled: 'is-disabled',
    active: 'is-active',
    visible: 'is-visible',
    dots: 'carousel__pagination',
    dot: 'carousel__pagination-item',
  },
};

export default class Carousel {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };
    this.element.ODS_Carousel = this;

    this.fixPaginationButtonType = this.fixPaginationButtonType.bind(this);

    this.element.ODS_Carousel = this;

    this.init();
    return this;
  }

  init() {
    this.getElements();

    if (this.element.hasAttribute('data-glider-options')) {
      this.getCustomOptions();
    }

    this.element.addEventListener(
      'glider-loaded',
      this.fixPaginationButtonType
    );
    this.element.addEventListener(
      'glider-refresh',
      this.fixPaginationButtonType
    );

    this.instance = new Glider(this.viewport, this.config);
  }

  getElements() {
    this.viewport = this.element.querySelector(SELECTOR_VIEWPORT);
    const elements = {
      arrows: {
        prev: this.element.querySelector(SELECTOR_PREV),
        next: this.element.querySelector(SELECTOR_NEXT),
      },
      dots: this.element.querySelector(SELECTOR_DOTS),
    };

    this.config = {
      ...this.config,
      ...elements,
    };
  }

  getCustomOptions() {
    const passedGliderOptions = this.element.getAttribute(
      'data-glider-options'
    );
    const parsedGliderOptions = JSON.parse(passedGliderOptions);

    this.config = {
      ...this.config,
      ...parsedGliderOptions,
    };
  }

  fixPaginationButtonType() {
    const dots = Array.from(
      this.element.querySelectorAll(`${SELECTOR_DOTS} > *`)
    );

    dots.forEach(dot => dot.setAttribute('type', 'button'));
  }

  destroy() {
    this.instance.destroy();
  }

  update() {
    this.instance.refresh();
  }

  static getInstance(el) {
    return el && el.ODS_Carousel ? el.ODS_Carousel : null;
  }
}
