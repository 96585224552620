// ---------------------------------------------------
// Tabs
// module for handling tabs

import { RovingTabindex } from '../../utils/keyboard';

export const defaultConfig = {
  tabSelector: '[role="tab"]',
};

export const configDocs = {
  tabSelector: 'Tab element selector',
};

export default class Tabs {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.tabs = [];
    this.tabPanels = [];
    this.activeTabIndex = null;

    this.rovingTabindex = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleTabFocus = this.handleTabFocus.bind(this);

    this.element.ODS_Tabs = this;

    this.init();

    return this;
  }

  static getInstance(el) {
    return el && el.ODS_Tabs ? el.ODS_Tabs : null;
  }

  init() {
    this.tabs = Array.from(
      this.element.querySelectorAll(this.config.tabSelector)
    );

    this.tabs.map((tab, index) => {
      this.tabPanels.push(
        document.getElementById(tab.getAttribute('aria-controls'))
      );
      tab.addEventListener('click', this.handleClick);
      tab.addEventListener('focus', this.handleTabFocus);

      if (this.isSelected(tab)) {
        this.activeTabIndex = index;
      }

      return tab;
    });

    if (!this.rovingTabindex) {
      this.rovingTabindex = new RovingTabindex(this.tabs, {
        direction: 'any',
      });
    } else {
      this.rovingTabindex.update(this.tabs);
    }
  }

  destroy() {
    this.tabs.map(tab => {
      tab.removeEventListener('click', this.handleClick);
      tab.removeEventListener('focus', this.onFocus);
      return tab;
    });

    this.tabs = [];
    this.tabPanels = [];
    this.rovingTabindex.destroy();
    this.element.ODS_Tabs = null;
  }

  update() {
    this.destroy();
    this.init();
  }

  isActive = el => !el.hasAttribute('hidden');

  isSelected = el => el.getAttribute('aria-selected') === 'true';

  handleClick(e) {
    const clickedTab = e.currentTarget;

    if (e.currentTarget.hasAttribute('aria-disabled')) {
      e.preventDefault();
      return;
    }

    this.tabs.map((tab, i) => {
      if (tab === clickedTab) {
        this.activeTabIndex = i;
        return this.toggleTab(tab, 'on');
      }
      return this.toggleTab(tab, 'off');
    });

    this.tabPanels.map(tabPanel => {
      if (
        tabPanel.getAttribute('id') === clickedTab.getAttribute('aria-controls')
      ) {
        return this.toggleTabPanel(tabPanel, 'on');
      }
      return this.toggleTabPanel(tabPanel, 'off');
    });
  }

  toggleTab(el, state) {
    const isActive = this.isSelected(el);

    if (state === 'on' && !isActive) {
      el.setAttribute('aria-selected', 'true');
      el.setAttribute('tabindex', '0');
    }

    if (state === 'off' && isActive) {
      el.setAttribute('aria-selected', 'false');
      el.setAttribute('tabindex', '-1');
    }
  }

  toggleTabPanel(el, state) {
    const isActive = this.isActive(el);

    if (state === 'on' && !isActive) {
      el.removeAttribute('hidden');
    }

    if (state === 'off' && isActive) {
      el.setAttribute('hidden', '');
    }
  }

  activateNthTab(index) {
    this.tabs.map((tab, i) => {
      if (i === index) {
        this.activeTabIndex = i;
        return this.toggleTab(tab, 'on');
      }
      return this.toggleTab(tab, 'off');
    });

    this.tabPanels.map((tabPanel, i) => {
      if (i === index) {
        return this.toggleTabPanel(tabPanel, 'on');
      }
      return this.toggleTabPanel(tabPanel, 'off');
    });
  }

  handleTabFocus() {
    if (!this.rovingTabindex.isActive) {
      this.rovingTabindex.init();
    }
  }
}
