export const breakpoints = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1240,
  xxl: 1380,
};

// we use array to include old browser key names like IE11
// ie. navigation keys https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#navigation_keys
export const keys = {
  TAB: ['Tab'],
  ENTER: ['Enter'],
  ESC: ['Esc', 'Escape'],
  SPACE: ['Spacebar', ' '],
  ARROWLEFT: ['Left', 'ArrowLeft'],
  ARROWUP: ['Up', 'ArrowUp'],
  ARROWRIGHT: ['Right', 'ArrowRight'],
  ARROWDOWN: ['Down', 'ArrowDown'],
};
