export default class SameHeight {
  constructor(element) {
    this.element = element;
    this.children = null;
    this.updateHeight = this.updateHeight.bind(this);
    this.element.ODS_SameHeight = this;
    this.init();

    return this;
  }

  updateHeight() {
    const rows = [];
    let newRow = [];
    if (this.children.length) {
      let lastOffset = this.children[0].getBoundingClientRect().top;
      for (let child of this.children) {
        if (child.getBoundingClientRect().top === lastOffset) {
          newRow.push(child);
        } else {
          rows.push([...newRow]);
          newRow = [];
          newRow.push(child);
        }
        lastOffset = child.getBoundingClientRect().top;
      }
      rows.push([...newRow]);
      rows.forEach(row => {
        if (row.length > 1) {
          let maxHeight = 0;
          row.forEach(child => {
            if (child) {
              if (child.style.height) {
                child.style.height = '';
              }
              if (child.offsetHeight > maxHeight) {
                maxHeight = child.offsetHeight;
              }
            }
          });
          row.forEach(child => {
            child.style.height = maxHeight + 'px';
          });
        } else {
          row.forEach(child => {
            if (child.style.height) {
              child.style.height = null;
            }
          });
        }
      });
    }
  }

  init() {
    let imgLoaded = [];
    this.children = this.element.querySelectorAll(
      '[data-same-height-child="true"]'
    );
    // check if some of data-same-height-child contain img
    this.children &&
      this.children.forEach(child => {
        const img = child.querySelector('img');
        if (img && !img.complete) {
          imgLoaded.push(img);
        }
      });
    // function executed on init when there are no img children or all img are loaded
    if (imgLoaded.length === 0) {
      this.updateHeight();
    }
    // if there's unloaded images we check if they did load every 400ms
    // TODO: add onload event to unload images would probably be a better solution
    else {
      let x = 0;
      const interval = setInterval(() => {
        let completed = false;
        imgLoaded.forEach((img, index, array) => {
          if (img.complete) {
            array.splice(index, 1);
            completed = true;
          }
        });
        completed && this.updateHeight && this.updateHeight();
        if (++x >= 15 || imgLoaded.length === 0) {
          imgLoaded.length && this.updateHeight && this.updateHeight();
          window.clearInterval(interval);
        }
      }, 400);
    }
    window.addEventListener('resize', this.updateHeight);
  }

  update() {
    this.destroy();
    this.init();
  }

  destroy() {
    window.removeEventListener('resize', this.updateHeight);
  }

  static getInstance(el) {
    return el && el.ODS_SameHeight ? el.ODS_SameHeight : null;
  }
}
