import { RovingTabindex } from '../../utils/keyboard';
import toggle from '../../scripts/modules/toggleUtil';

const defaultConfig = {
  buttonSelector: '[data-accordion-toggle]',
};

export default class Accordion {
  constructor(element, config) {
    this.element = element;

    this.config = { ...defaultConfig, ...config };

    this.onFocus = this.onFocus.bind(this);
    this.onClick = this.onClick.bind(this);

    this.element.ODS_Accordion = this;

    this.init();

    return this;
  }

  onFocus(e) {
    if (!this.rovingTabindex.isActive) {
      this.rovingTabindex.init();
    }

    const button = e.currentTarget;

    if (
      window.location.hash.substring(1) === button.getAttribute('id') &&
      button.getAttribute('aria-expanded') === 'false'
    ) {
      button.click();
    }
  }

  init() {
    this.buttons = Array.from(
      this.element.querySelectorAll(this.config.buttonSelector)
    );

    if (!this.rovingTabindex) {
      this.rovingTabindex = new RovingTabindex(this.buttons, {
        direction: 'vertical',
      });
    } else {
      this.rovingTabindex.update(this.buttons);
    }

    this.buttons.forEach(button => {
      button.addEventListener('focus', this.onFocus);

      // only add click events when there are no toggle actions defined
      if (!button.getAttribute('data-toggle')) {
        button.addEventListener('click', this.onClick);
      }
    });
  }

  onClick(e) {
    const button = e.currentTarget;
    const accordionSection = this.element.querySelector(
      `#${button.getAttribute('aria-controls')}`
    );

    toggle({
      element: button,
      attribute: 'aria-expanded',
    });

    toggle({
      element: accordionSection,
      attribute: 'hidden',
    });
  }

  destroy() {
    this.rovingTabindex.destroy();

    this.buttons.forEach(button => {
      button.removeEventListener('focus', this.onFocus);
      button.removeEventListener('click', this.onClick);
    });
  }

  update() {
    this.destroy();
    this.init();
  }

  static getInstance(el) {
    return el && el.ODS_Accordion ? el.ODS_Accordion : null;
  }
}
