import wnumb from 'wnumb';

export function isObject(val) {
  return val != null && typeof val === 'object' && Array.isArray(val) === false;
}

export function dateToYYYYMMDD(date) {
  if (!date || !(date instanceof Date)) {
    return '';
  }

  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

// Get element position relative to document
export function windowOffset(el) {
  const position = { x: 0, y: 0 };

  /* eslint-disable no-cond-assign, no-param-reassign */
  if (el.offsetParent) {
    do {
      position.x += el.offsetLeft;
      position.y += el.offsetTop;
    } while ((el = el.offsetParent));
  }
  /* eslint-enable */

  return position;
}

// Get element siblings
export function getSiblings(el) {
  if (!(el instanceof Element)) {
    return false;
  }

  const parent = el.parentNode;
  if (!parent) {
    return false;
  }

  const parentChildren = [];
  const siblings = [];

  parentChildren.push.apply(parentChildren, parent.children); // eslint-disable-line prefer-spread

  for (let i = 0, l = parentChildren.length; i < l; i += 1) {
    if (parentChildren[i] !== el) {
      siblings.push(parentChildren[i]);
    }
  }

  return siblings;
}

/**
 * Converts object to string
 *
 * Should be able to handle all types
 * objectToString({
 *   'boolean': true,
 *   'function': () => { return 'test'; },
 *   'namedfunction': function test() { return 'test'; },
 *   'null': null,
 *   'number1': 1,
 *   'number2': 2.345,
 *   'object1': { test: 'test' },
 *   'string': 'String',
 *   'undefined' : undefined,
 * })
 *
 * @param {object} object object to convert
 * @returns object converted to string
 */
export const objectToString = (object, spaceCount = 2) => {
  let str = '';
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      let value = object?.[key];
      if (typeof value === 'string') {
        value = `"${value}"`;
      } else if (value === null) {
        value = 'null';
      } else if (typeof value === 'object') {
        value = objectToString(value, spaceCount + 2);
      } else if (typeof value === 'function') {
        value = value.toString();
      } else if (value === undefined) {
        value = 'undefined';
      }
      str += `${' '.repeat(spaceCount)}${key}: ${value},\n`;
    }
  }
  return `{\n${str}${' '.repeat(spaceCount - 2)}}`;
};
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lowerCamelCase(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

const defaultPriceFormat = {
  decimals: 2,
  mark: ',',
  thousand: ' ',
  suffix: `\xa0€`,
};

export function formatPrice(price, format) {
  return wnumb({
    ...defaultPriceFormat,
    ...format,
    ...(price % 1 === 0 ? { decimals: 0 } : {}), // integers without decimals
  }).to(price);
}
