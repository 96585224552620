const defaultConfig = {
  controlSelector: '.block-action__control',
  indicatorSelector: '.block-action__indicator',
  activeClass: 'is-indicating',
};

export default class BlockAction {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.controlElement = null;
    this.indicatorElements = [];

    this.highlightIndicators = this.highlightIndicators.bind(this);
    this.unhighlightIndicators = this.unhighlightIndicators.bind(this);

    this.element.ODS_BlockAction = this;

    this.init();

    return this;
  }

  init() {
    this.controlElement = this.element.querySelector(
      this.config.controlSelector
    );
    this.indicatorElements = Array.from(
      this.element.querySelectorAll(this.config.indicatorSelector)
    );

    if (!this.controlElement || this.indicatorElements.length < 1) {
      return;
    }

    this.controlElement.addEventListener(
      'mouseenter',
      this.highlightIndicators
    );
    this.controlElement.addEventListener(
      'mouseleave',
      this.unhighlightIndicators
    );
    this.controlElement.addEventListener('focus', this.highlightIndicators);
    this.controlElement.addEventListener('blur', this.unhighlightIndicators);
  }

  highlightIndicators() {
    this.indicatorElements.forEach(el =>
      el.classList.add(this.config.activeClass)
    );
  }

  unhighlightIndicators() {
    this.indicatorElements.forEach(el =>
      el.classList.remove(this.config.activeClass)
    );
  }

  destroy() {
    this.indicatorElements.forEach(el =>
      el.classList.remove(this.config.activeClass)
    );
    this.controlElement.removeEventListener(
      'mouseenter',
      this.highlightIndicators
    );
    this.controlElement.removeEventListener(
      'mouseleave',
      this.unhighlightIndicators
    );
    this.controlElement.removeEventListener('focus', this.highlightIndicators);
    this.controlElement.removeEventListener('blur', this.unhighlightIndicators);

    this.controlElement = null;
    this.indicatorElements = [];
  }

  update() {
    this.destroy();
    this.init();
  }

  static getInstance(el) {
    return el && el.ODS_BlockAction ? el.ODS_BlockAction : null;
  }
}
