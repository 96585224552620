import Glider from '@lighting-beetle/glider-js';

import {
  SELECTOR_NEXT,
  SELECTOR_DOTS,
  SELECTOR_VIEWPORT,
  CLASS_DOT_CIRCLE,
  CLASS_DOT_SVG,
  CLASS_AUTOPLAY,
  CLASS_SHOWING_INVERTED_SLIDE,
} from './constants';

export const defaultConfig = {
  arrows: {
    next: SELECTOR_NEXT,
  },
  slidesToShow: 1,
  dots: SELECTOR_DOTS,
  skipTrack: true,
  rewind: true,
  interval: 0,
  classes: {
    glider: 'carousel-promotions__viewport',
    track: 'carousel-promotions__track',
    slide: 'carousel-promotions__slide',
    drag: 'is-dragging',
    draggable: 'is-draggable',
    disabled: 'is-disabled',
    active: 'is-active',
    visible: 'is-visible',
    dots: 'carousel-promotions__pagination',
    dot: 'carousel-promotions__pagination-item',
  },
};

export default class CarouselPromotions {
  interval = 0;

  constructor(element, config) {
    this.element = element;
    const mergedConfig = { ...defaultConfig, ...config };
    const { interval, ...restConfig } = mergedConfig;
    this.config = restConfig;
    this.interval = parseInt(interval);
    this.element.ODS_CarouselPromotions = this;

    this.gliderLoadedHandler = this.gliderLoadedHandler.bind(this);
    this.gliderRefreshHandler = this.gliderRefreshHandler.bind(this);
    this.scrollToNextSlide = this.scrollToNextSlide.bind(this);
    this.autoplayAnimationEnd = this.autoplayAnimationEnd.bind(this);

    this.element.ODS_CarouselPromotions = this;

    this.init();
    return this;
  }

  init() {
    this.getElements();

    if (this.element.hasAttribute('data-glider-options')) {
      this.getCustomOptions();
    }

    if (this.element.hasAttribute('data-interval')) {
      this.interval = parseInt(this.element.getAttribute('data-interval'));
    }

    const isIntervalValid = this.isIntervalValid();

    if (isIntervalValid) {
      this.element.classList.add(CLASS_AUTOPLAY);
    } else {
      this.interval = 0;
    }

    this.element.addEventListener('glider-loaded', this.gliderLoadedHandler);
    this.element.addEventListener('glider-refresh', this.gliderRefreshHandler);
    this.element.addEventListener('glider-slide-visible', (e, foo) => {
      this.element.classList.remove(CLASS_SHOWING_INVERTED_SLIDE);
      if (e.detail && e.detail.slide) {
        const slideIndex = e.detail.slide;
        if (slideIndex % 2 === 1) {
          this.element.classList.add(CLASS_SHOWING_INVERTED_SLIDE);
        }
      }
    });

    this.instance = new Glider(this.viewport, this.config);

    if (this.instance && this.instance.dots && isIntervalValid) {
      this.instance.dots.addEventListener(
        'animationend',
        this.autoplayAnimationEnd
      );
    }
  }

  autoplayAnimationEnd(e) {
    if (e instanceof AnimationEvent && e.animationName === 'countdown') {
      this.scrollToNextSlide();
    }
  }

  get isAutoplayEnabled() {
    return this.interval > 0;
  }

  /**
   * Validates the interval attribute. The attribute must be a valid integer greater than or equal to 1000.
   * @return {boolean}
   */
  isIntervalValid() {
    const interval = parseInt(this.interval);
    return !isNaN(interval) && interval >= 1000;
  }

  scrollToNextSlide() {
    const slidesCount = this.instance.slides.length;
    if (slidesCount < 0) {
      return;
    }
    const currentSlideIndex = this.instance.getCurrentSlide();
    this.instance.scrollItem((currentSlideIndex + 1) % slidesCount);
  }

  gliderLoadedHandler() {
    this.fixPaginationButtonType();
    if (this.isAutoplayEnabled) {
      this.renderSvgDotsForAnimation();
    }
  }

  gliderRefreshHandler() {
    this.fixPaginationButtonType();
    if (this.isAutoplayEnabled) {
      this.renderSvgDotsForAnimation();
    }
  }

  getElements() {
    this.viewport = this.element.querySelector(SELECTOR_VIEWPORT);
    const elements = {
      arrows: {
        next: this.element.querySelector(SELECTOR_NEXT),
      },
      dots: this.element.querySelector(SELECTOR_DOTS),
    };

    this.config = {
      ...this.config,
      ...elements,
    };
  }

  getCustomOptions() {
    const passedGliderOptions = this.element.getAttribute(
      'data-glider-options'
    );
    const parsedGliderOptions = JSON.parse(passedGliderOptions);

    this.config = {
      ...this.config,
      ...parsedGliderOptions,
    };
  }

  fixPaginationButtonType() {
    const dots = Array.from(
      this.element.querySelectorAll(`${SELECTOR_DOTS} > *`)
    );

    dots.forEach(dot => dot.setAttribute('type', 'button'));
  }

  /**
   * Creates an SVG element representing a pagination dot.
   * We must create the SVG element with `createElement`, because the Glider.js library
   * overwrites the content of the pagination dots.
   * @return {SVGSVGElement}
   */
  static createSvgDot() {
    const svgNS = 'http://www.w3.org/2000/svg';
    const svg = document.createElementNS(svgNS, 'svg');
    svg.setAttribute('class', CLASS_DOT_SVG);
    svg.setAttribute('width', '12');
    svg.setAttribute('height', '12');
    svg.setAttribute('viewBox', '0 0 12 12');
    svg.setAttribute('fill', 'none');
    svg.setAttribute('xmlns', svgNS);

    const circle = document.createElementNS(svgNS, 'circle');
    circle.setAttribute('class', CLASS_DOT_CIRCLE);
    circle.setAttribute('r', '5');
    circle.setAttribute('cx', '6');
    circle.setAttribute('cy', '6');

    svg.appendChild(circle);

    return svg;
  }

  /**
   * Renders an SVG element inside each pagination dot.
   */
  renderSvgDotsForAnimation() {
    const dots = Array.from(
      this.element.querySelectorAll(`${SELECTOR_DOTS} > *`)
    );

    const svg = CarouselPromotions.createSvgDot();
    svg.querySelector('circle').style.animationDuration = `${this.interval}ms`;

    dots.forEach(dot => {
      dot.innerHTML = '';
      dot.appendChild(svg.cloneNode(true));
    });
  }

  destroy() {
    this.instance.destroy();
  }

  update() {
    this.instance.refresh();
  }

  static getInstance(el) {
    return el && el.ODS_CarouselPromotions ? el.ODS_CarouselPromotions : null;
  }
}
