import { keys } from './constants';

// eslint-disable-next-line import/prefer-default-export
export class RovingTabindex {
  constructor(
    elems,
    config = {
      direction: 'horizontal',
    }
  ) {
    this.elems = elems;
    this.config = config;

    this.isActive = false;

    this.onKeydown = this.onKeydown.bind(this);
  }

  init() {
    document.addEventListener('keydown', this.onKeydown);
    this.isActive = true;
  }

  destroy() {
    document.removeEventListener('keydown', this.onKeydown);
    this.isActive = false;
  }

  update(elems) {
    this.elems = elems;
  }

  onKeydown(e) {
    const activeElemIndex = this.elems.indexOf(document.activeElement);

    if (
      (keys.ARROWUP.includes(e.key) || keys.ARROWDOWN.includes(e.key)) &&
      this.config.direction === 'horizontal'
    ) {
      return;
    }

    if (
      (keys.ARROWLEFT.includes(e.key) || keys.ARROWRIGHT.includes(e.key)) &&
      this.config.direction === 'vertical'
    ) {
      return;
    }

    if (keys.ARROWDOWN.includes(e.key) || keys.ARROWRIGHT.includes(e.key)) {
      e.preventDefault();
      if (activeElemIndex >= 0 && activeElemIndex < this.elems.length - 1) {
        this.elems[activeElemIndex + 1].focus();
      } else {
        this.elems[0].focus();
      }
    }

    if (keys.ARROWUP.includes(e.key) || keys.ARROWLEFT.includes(e.key)) {
      e.preventDefault();
      if (activeElemIndex >= 1) {
        this.elems[activeElemIndex - 1].focus();
      } else {
        this.elems[this.elems.length - 1].focus();
      }
    }

    if (keys.TAB.includes(e.key)) {
      this.destroy();
    }
  }
}
