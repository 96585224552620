import accessibleAutocomplete from '@orangesk/accessible-autocomplete';

export const defaultConfig = {
  autoselect: true,
  /**
   * defaults to 'autocomplete__menu'
   * left empty to prevent adding 'undefined' class to html element
   */
  wrapperClassName: '',
  /**
   * defaults to 'autocomplete__option'
   * left empty to prevent adding 'undefined' class to html element
   */
  menuClassName: '',
  /**
   * defaults to 'autocomplete__option'
   * left empty to prevent adding 'undefined' class to html element
   */
  optionClassName: '',
  customInputClassName: '',
  displayMenu: 'overlay',
  tAssistiveHint: () =>
    'Pre prezeranie výsledkov automatického dopĺňania použite šípky nahor a nadol a pre výber konkrétneho výsledku použite tlačidlo Enter. Pri dotykových zariadeniach použite dotyk alebo potiahnutie prstom do strany.',
};

export default class Autocomplete {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };
    this.onPageshow = this.onPageshow.bind(this);
    this.instance = null;

    this.init();

    this.element.ODS_Autocomplete = this;

    return this;
  }

  init() {
    const elementConfig = JSON.parse(
      this.element.getAttribute('data-autocomplete-config')
    );
    this.config = { ...this.config, ...elementConfig };

    this.element.tagName === 'SELECT'
      ? accessibleAutocomplete.enhanceSelectElement({
          selectElement: this.element,
          ...this.config,
        })
      : accessibleAutocomplete({
          element: this.element,
          ...this.config,
        });

    window.addEventListener('pageshow', this.onPageshow);
  }

  onPageshow(e) {
    if (e.persisted && window) {
      // if shown page originates from cache, let's reload it to prevent buggy behavior
      window.location.reload();
    }
  }
}
