export const CLASS_ROOT = 'carousel';

export const CLASS_WRAPPER = `${CLASS_ROOT}`;
export const CLASS_VIEWPORT_WRAPPER = `${CLASS_ROOT}__viewport-wrapper`;
export const CLASS_VIEWPORT = `${CLASS_ROOT}__viewport`;
export const CLASS_BLINDS = `${CLASS_ROOT}__blinds`;
export const CLASS_TRACK = `${CLASS_ROOT}__track`;
export const CLASS_SLIDE = `${CLASS_ROOT}__slide`;
export const CLASS_PREV = `${CLASS_ROOT}__prev`;
export const CLASS_NEXT = `${CLASS_ROOT}__next`;
export const CLASS_DOTS = `${CLASS_ROOT}__pagination`;

export const SELECTOR_WRAPPER = `.${CLASS_WRAPPER}`;
export const SELECTOR_VIEWPORT = `.${CLASS_VIEWPORT}`;
export const SELECTOR_PREV = `.${CLASS_PREV}`;
export const SELECTOR_NEXT = `.${CLASS_NEXT}`;
export const SELECTOR_DOTS = `.${CLASS_DOTS}`;
