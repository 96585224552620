const defaultConfig = {
  cycleDuration: 4000,
  delay: 5000,
};

export default class Loader {
  constructor(element, config) {
    this.element = element;
    this.config = { ...defaultConfig, ...config };

    this.interval = null;
    this.delay = null;
    this.currentMessageIndex = 0;
    this.messages = [];
    this.cycleMessages = this.cycleMessages.bind(this);

    this.element.ODS_Loader = this;

    this.init();
  }

  initBody() {
    this.messages = JSON.parse(
      this.element.getAttribute('data-loader-messages')
    );

    if (this.messages.length) {
      this.interval = window.setInterval(
        this.cycleMessages,
        this.config.cycleDuration
      );
    }
  }

  init() {
    if (this.element.hasAttribute('data-loader-delayed')) {
      this.delay = window.setTimeout(() => {
        this.element.parentNode.classList.remove('hide');
        this.initBody();
      }, this.config.delay);
    } else {
      this.initBody();
    }
  }

  destroy() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
    if (this.delay) {
      window.clearInterval(this.delay);
    }
  }

  cycleMessages() {
    if (this.currentMessageIndex < this.messages.length) {
      this.element.innerText = this.messages[this.currentMessageIndex];
      this.currentMessageIndex += 1;
    } else {
      this.destroy();
    }
  }

  static getInstance(el) {
    return el && el.ODS_Loader ? el.ODS_Loader : null;
  }
}
